import { signOut, signInAnonymously, updateProfile, deleteUser, User, GoogleAuthProvider, TwitterAuthProvider, FacebookAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithCredential } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { AuthenticationType } from "@/app/context/authentication/type/authenticationType";
import ShowLoginModal from "@/app/context/authentication/layout/modal/showLoginModal";
import { GlobalDataContext } from "../global/globalDataContext";
import { FirebaseContext } from "../firebase/firebaseContext";
import CloudStorageInterface from "@/app/interface/CloudStorageInterface";
import CloudStorageFirestoreHelper from "@/app/helpers/CloudStorageFirestoreHelper";
import { NotificationContext } from "../notification/notificationContext";
import { useRouter } from "next/router";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { WebviewType } from "@/app/types/webviewType";

export const AuthenticationContext = createContext({} as AuthenticationType)

type Props = {
    firebaseConfig: {
        apiKey: string | undefined
        authDomain: string | undefined
        projectId: string | undefined
        storageBucket: string | undefined
        messagingSenderId: string | undefined
        appId: string | undefined
        measurementId: string    | undefined      
    },
    children: React.ReactNode 
}

export const AuthenticationProvider= ({
    firebaseConfig,
    children
}:Props) => {

    const { t } = useTranslation();
    const router = useRouter();
    
    const [isAuthLoading, setIsAuthLoading] = useState(true)
    const [user, setUser] = useState<any|null>(null)
    const [isAdmin, setIsAdmin] = useState(false)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [logOut, setLogOut] = useState(false)
    const [cloudStorage,setCloudStorage] = useState<CloudStorageInterface|null>(null)
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)
    const [messagingToken, setMessagingToken] = useState('');
    const [isSigning, setIsSigning] = useState(false)
    const [wasUserNotConnected, setWasUserNotConnected] = useState(false)
    const [isNewUser, setIsNewUser] = useState(false)
    const [googleAuthToken, setGoogleAuthToken] = useState<string|null>(null)

   const {
        appState,
        authState,
        dbState
    }=useContext(FirebaseContext)

    const {
        addError,
        addMessageWithId
    } = useContext(NotificationContext)

    useEffect(()=>{
        if ( !user && !isAuthLoading) {
            setWasUserNotConnected(true)
        }
        if (!isAuthLoading && user){
            setWasUserNotConnected(false)
        }
    },[isAuthLoading,user])


    useEffect(() => {
        if ( appState) {
           const retrieveToken = async () => {
                try {
                    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {

                        const messaging = getMessaging(appState);
                
                        // Retrieve the notification permission status
                        const permission = await Notification.requestPermission();
                        //setNotificationPermissionStatus(permission);
                
                        // Check if permission is granted before retrieving the token
                        if (permission === 'granted') {
                            const currentToken = await getToken(messaging, {
                            vapidKey:
                                process.env.NEXT_PUBLIC_FIREBASE_VAPIDKEY,
                            });
                            if (currentToken) {
                                if ( process.env.NODE_ENV==="development" ) {    
                                    console.log("FCM token",currentToken)
                                }
                                setMessagingToken(currentToken);
                            } else {
                                if ( process.env.NODE_ENV==="development" ) {
                                    console.log(
                                        'No registration token available. Request permission to generate one.'
                                    );
                                }
                            }
                        }

                        const unsubscribe = onMessage(messaging, (payload) => {
                            if ( process.env.NODE_ENV==="development" ) {
                                console.log('Foreground push notification received:', payload);
                            }       
                            addMessageWithId("notification"+payload?.data?.title,payload?.data?.body as string, "message", "unread", null)
                                 
                          });

                        return () => {
                            unsubscribe(); // Unsubscribe from the onMessage event
                          };
                    }
                } catch (error) {
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log('An error occurred while retrieving token:', error);
                    }
                }
                };
            
                retrieveToken();
        }
      }, [appState]);

      const sendNotificationPush = async({
        userId,
        title,
        body
      }:any) => {
        try {
            const options = {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    'Authorization': "Bearer " + await user?.getIdToken()
                },
                body: JSON.stringify({
                    userId : userId,
                    title : title,
                    body: body
                  })
            }
            if ( process.env.NODE_ENV==="development" ) {
                console.log("options",options, {
                    userId : userId,
                    title : title,
                    body: body
                })
            }
            const response = await fetch(process.env.NEXT_PUBLIC_API_URL_V2 + "push", options)
            const check = await response.json()
            
            return check
        } catch (error) {
            return false
        }
    };

    const anonymousLogin = ()=> {
        if ( authState) {
            signInAnonymously(authState)
            .then(() => {
                setIsAuthLoading(false)
            })
            .catch((error:any) => {
                const errorCode = error.code;
                const errorMessage = error.message;
            });
        }
    }

    useEffect(()=>{
        if ( authState ) {
            const unsubscribe = authState.onAuthStateChanged( async (user: User|null ) => {    
                setIsSigning(false)
                if (user && user.metadata.creationTime && user.metadata.lastSignInTime){
                    const creationTime = new Date(user.metadata.creationTime);
                    const lastSignInTime = new Date(user.metadata.lastSignInTime);
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("NEWUSER")
                    }
                    setIsNewUser(creationTime.getTime() === lastSignInTime.getTime());
                }else {
                    setIsNewUser(false)
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("USER EXISTANT OR NOT CONNECTED")
                    }
                }
                setIsAdmin(false)
                setCloudStorage(null)
                setUser(user )
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("authentication context : user state change",user)
                }
                setIsAuthLoading(false)
                setShowLoginModal(false)
            });

            return unsubscribe
        }
       
    },[authState])

    useEffect(()=>{
        if ( logOut && authState) {
            setWasUserNotConnected(false)
            setLogOut(false)
            setIsAuthLoading(true)
            signOut(authState).then(()=>{
                setCloudStorage(null)
            })
        }
    },[logOut])


    useEffect(()=>{
        if ( appState && dbState ){
            if ( user ) {
                try {
                   setCloudStorage(new CloudStorageFirestoreHelper(user,dbState)) 
                }catch(e){
                    setCloudStorage(null)
                }
            }      
           
        }
    },[user,dbState, appState])


    const updateProfileName=(name:string, callback:()=>void)=> {
        if ( user && authState ) {
            
            updateProfile(authState.currentUser as User, {
                displayName: name
            })
            .then(()=>callback())
            .catch((error:any)=> {
                addError(error.message || t("alert.technicalError"),"error")
            });
        }
    }

    useEffect(()=>{
        if ( showDeleteAccountModal && user && authState){
            const localUser = authState.currentUser
            deleteUser(localUser as User)
                .then(async() => {                  
                    setShowDeleteAccountModal(false)
                    addError(t("authentication.accountDeleted"),"info")                    
                })
                .catch((error:any)=> {
                    addError(error.message || t("alert.technicalError"),"error")
                });
        }
    },[showDeleteAccountModal,user, authState])


    const deleteUserConnected=()=> {
        if (user && authState){
            signOut(authState).then(()=>{
                setUser(null)
                setShowDeleteAccountModal(true)
            })
        }
    }

    useEffect(()=>{
        if ( authState && googleAuthToken){
            signInWithGoogleToken(googleAuthToken)
        }
    },[authState,googleAuthToken])

    const signInWithGoogleToken = async(token:string) => {
        if ( authState) {
            try {
                const credential = GoogleAuthProvider.credential(token)

                const userCredential = await signInWithCredential(authState, credential)
                if ( !userCredential.user) {
                    addError(t("authentication.impossibleWithThisMethod"), "error");
                    return
                }
            } catch (error) {
                addError(t("authentication.impossibleWithThisMethod"), "error");
            
                if (process.env.NODE_ENV === "development") {
                console.error("Erreur lors de l'authentification avec Google", error);
                }
            }
        }
    }

    const signInWithGoogle = async() => {
        if (window.ReactNativeWebView) {
            const data:WebviewType= {
                domain: "GOOBLIES",
                action: "authentication"
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(data));
        }else if ( authState ) {
            setIsSigning(true)
            //setWasUserNotConnected(false)
            const provider = new GoogleAuthProvider();

            try {
               await signInWithPopup(authState, provider);
            } catch (error) {
                addError(t("authentication.impossibleWithThisMethod"),"error")
                if ( process.env.NODE_ENV==="development" ) {
                    console.error("Error signing in with Google",error)
                }
            }
        }
    }

    const signInWithFacebook= async() => {
        if ( authState ) {
            setIsSigning(true)
            //setWasUserNotConnected(false)
            const provider = new FacebookAuthProvider();

            try {
                await signInWithPopup(authState, provider);
            } catch (error) {
                addError(t("authentication.impossibleWithThisMethod"),"error")                
                if ( process.env.NODE_ENV==="development" ) {
                    console.error("Error signing in with Facebook",error)
                }
            }
        }
    }

    const signInWithTwitter = async() => {
        if ( authState ) {
            setIsSigning(true)
            //setWasUserNotConnected(false)
            const provider = new TwitterAuthProvider();

            try {
                await signInWithPopup(authState, provider);
            } catch (error) {
                addError(t("authentication.impossibleWithThisMethod"),"error")
                if ( process.env.NODE_ENV==="development" ) {
                    console.error("Error signing in with Twitter",error)
                }
            }
        }
    }

    const signInWithCredentials = async(email:string, password:string) => {
        if ( authState ) {
            setIsSigning(true)
            //setWasUserNotConnected(false)
            try {
                await signInWithEmailAndPassword(authState, email, password);                
            } catch (error) {
                addError(t("authentication.loginOrPasswordIncorrect"),"error")
                if ( process.env.NODE_ENV==="development" ) {
                    console.error("Error signing in with email and password",error)
                }
                return false
            }
        }
        return true
    }

    const createUserWithCredentials = async(email:string, password:string) => {
        if ( authState ) {
            setIsSigning(true)
            //setWasUserNotConnected(false)
            try {
                await createUserWithEmailAndPassword(authState, email, password);                
            } catch (error) {
                addError(t("authentication.creationError"),"error")
                if ( process.env.NODE_ENV==="development" ) {
                    console.error("Error signing in with email and password",error)
                }
                return false
            }
        }
        return true
    }

    const resetPasswordByEmail = async(email:string) => {
        if ( authState ) {
            try {
                authState.useDeviceLanguage()
                await sendPasswordResetEmail(authState, email);            
            } catch (error) {
                addError(t("authentication.impossibleToResetPassword"),"error")
                if ( process.env.NODE_ENV==="development" ) {
                    console.error("Error signing in with email and password",error)
                }
                return false
            }
        }
        return true
    }

    return (
        <AuthenticationContext.Provider value={{
            user,
            isAdmin,
            showLoginModal,
            setShowLoginModal,
            setLogOut,
            isAuthLoading,
            cloudStorage,
            updateProfileName,
            showDeleteAccountModal,
            deleteUserConnected,
            anonymousLogin,
            signInWithGoogle,
            signInWithFacebook,
            signInWithTwitter,
            signInWithCredentials,
            createUserWithCredentials,
            resetPasswordByEmail,
            messagingToken,
            sendNotificationPush,
            isSigning,
            logOut,
            wasUserNotConnected,
            isNewUser,
            setGoogleAuthToken
        }}>
            {children}
            <ShowLoginModal/>
        </AuthenticationContext.Provider>
    )
}


