import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { NotificationType } from './type/notificationType';
import { NotificationInfoType } from './type/notificationInfoType';
import { NotificationMessageType } from './type/notificationMessageType';
import { Box, useToast } from '@chakra-ui/react';
import { v4 } from 'uuid';
import ConfettiExplosion from 'react-confetti-explosion';
import { debounce } from 'lodash';
import { WebviewType } from '@/app/types/webviewType';
import { CharacterType } from './type/characterType';
import { AllCharactersConst } from '@/app/const/allCharactersConst';
import { getLocalStorage } from '@/app/helpers/localStorageHelper';
import ChooseCharacter from './layout/chooseCharacter';

export const NotificationContext = createContext({} as NotificationType);

export const NotificationProvider = (props:any) => {

    const toast = useToast()
    const [info, setInfo] = useState<NotificationInfoType[]>([])
    const [error, setError] = useState<NotificationInfoType[]>([])
    const [messages, setMessages] = useState<NotificationMessageType[]>([])
    const [voiceTalking, setVoiceTalking] = useState(false)
    const [doVibrate, setDoVibrate] = useState<boolean|number[]>(false);
    const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState(0)
    const [queue, setQueue] = useState<NotificationMessageType[]>([]);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [modalQueue, setModalQueue] = useState<any[]>([]);
    const [currentModal, setCurrentModal] = useState<any>(null);
    const [defaultCharacter, setDefaultCharacter] = useState<number|null|undefined>(undefined);
    const [character, setCharacter] = useState<CharacterType|null>(null);
    const voiceTalkingRef = useRef(voiceTalking);
    const [isReady, setIsReady] = useState(false);

    useEffect(()=>{
        setIsReady(true);
        const storage = getLocalStorage("defaultCharacter");
        if ( storage || storage === 0){
            setDefaultCharacter(storage);
        }else{
            if (window.ReactNativeWebView) {

            }else{
                setDefaultCharacter(0);
            }
        }
    },[])

    useEffect(()=>{
        if (defaultCharacter !== null && defaultCharacter !== undefined) {
            setCharacter(AllCharactersConst[defaultCharacter]);
        }
    },[defaultCharacter])

    useEffect(() => {
        voiceTalkingRef.current = voiceTalking;
    }, [voiceTalking]);

    useEffect(() => {
        let timeoutId:any=null;
        if (voiceTalking) {
          // Timeout de sécurité de 30 secondes
          timeoutId = setTimeout(() => {
            setVoiceTalking(false);
          }, 45000);
        }
        
        return () => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        };
      }, [voiceTalking]);

    useEffect(() => {
        if (queue.length > 0 ) {
            processQueue();
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [queue]);

   const processQueue = () => {
        if (queue.length === 0) {
            return;
        }
        const nextMessage= queue[0];        
        const now = Date.now();

        if (nextMessage.timestamp <= now ) {
            markMessageAsRead()
            setMessages((prevMessages) => [...prevMessages, nextMessage]);
            setQueue((prevQueue) => prevQueue.slice(1));
            //timeoutRef.current = null;
        } else {
            const delay = nextMessage.timestamp - now;
            timeoutRef.current = setTimeout(() => {
                processQueue();
            }, delay);
        }
    };

    const addInfo = (message:string, type:"info" | "error" | "warning" | "success") => {
        setInfo(prevInfo => [...prevInfo, { id: v4(), message, type }]);
    }

    const addError = (message:string, type:"info" | "error" | "warning" | "success") => {
        setError(prevError => [...prevError, { id: v4(), message, type }]);
    }

    /*const addMessage = (message:string, type: "message" , status: "read" | "unread" | "treated", action?:React.ReactNode) => {
        setMessages(prevMessages => [...prevMessages, { id: v4(), timestamp: Date.now(), message, type, status, action:action }]);
    }*/

    const isRecentMessage=()=>{
        const isInQueue = queue.length > 0;
        const lastMessage = messages[messages.length - 1];
        return isInQueue || (lastMessage && (Date.now() - lastMessage.timestamp < 10000));
    }

   /* const addMessageWithId = (id:string|null,message:string, type: "message" , status: "queued" | "read" | "unread" | "treated", timestamp : number | null, action?:React.ReactNode) => {        
        const lastMessage = messages[messages.length - 1];
        const now = timestamp ?? Date.now();

        const checkExist = ( queue.find(m => m.id === id) || messages.find(m => m.id === id));
        if (checkExist) {
            return;
        }
        let adjustedTimestamp = lastMessage && (now - lastMessage.timestamp < 10000)
            ? lastMessage.timestamp + 10000
            : now;

        // Vérifier les messages dans la queue
        const lastInQueue = queue.length > 0 ? queue[queue.length - 1] : null;

        if (lastInQueue && adjustedTimestamp - lastInQueue.timestamp < 10000) {
            adjustedTimestamp = lastInQueue.timestamp + 10000;
        }

        setQueue(prevQueue => {
            return [...prevQueue, { id: id??v4(), timestamp: adjustedTimestamp, message, type, status, action:action }]
        });
    }*/

    const addMessageWithId = (
        id: string | null,
        message: string,
        type: "message",
        status: "queued" | "read" | "unread" | "treated",
        timestamp: number | null,
        action?: React.ReactNode
        ) => {
        const now = timestamp ?? Date.now();
        
        setQueue((prevQueue) => {
            // Fusionner prevQueue et messages pour obtenir tous les messages existants
            const allMessages = [...prevQueue, ...messages];
        
            const checkExist = allMessages.find((m) => m.id === id);
            if (checkExist) {
            return prevQueue;
            }
        
            // Trouver le dernier timestamp parmi tous les messages
            const lastTimestamps = allMessages.map((m) => m.timestamp);
            const maxTimestamp = Math.max(...lastTimestamps, now);
        
            let adjustedTimestamp = maxTimestamp > now ? maxTimestamp + 10000 : now;

            return [
            ...prevQueue,
            {
                id: id ?? v4(),
                timestamp: adjustedTimestamp,
                message,
                type,
                status,
                action: action,
            },
            ];
        });
    };

    const emptyMessages = () => {
        setMessages([])
        setQueue([])
    }

    const markMessageAsRead = () => {
        voiceTalking && setVoiceTalking(false)            
        setMessages(prevMessages=> {
            return prevMessages.map(m => {
                if (m.status === "unread" && !m.action) {
                    return {...m, status:"read"}
                }
                return m
            })
        })
    }

    const markMessageAsTreated = (id:string) => {
        voiceTalking && setVoiceTalking(false)
        setMessages(prevMessages=> {
            return prevMessages.map(m => {
                if (m.id === id) {
                    return {...m, status:"treated"}
                }
                return m
            })
        })
    }

    useEffect(()=>{
        setNumberOfUnreadMessages(messages.filter(m => m.status === "unread" && m.timestamp <= Date.now() ).length)        
    },[messages])

    const addModalToQueue = useCallback((modalConfig:any) => {
        setModalQueue((prevQueue:any) => [...prevQueue, modalConfig]);
      }, []);
    
      const closeModal = useCallback(() => {
        setCurrentModal(null);
      }, []);
    
      const handleNextModal = useCallback(() => {
        if (modalQueue.length > 0) {
            const nextModal = modalQueue[0];
            setModalQueue((prevQueue) => prevQueue.slice(1));
            setCurrentModal(nextModal);
            if ( nextModal.doVibrate) {
                setDoVibrate(nextModal.doVibrate);
            }
            if (nextModal.onShow) {
                nextModal.onShow();
            }
        }
      }, [modalQueue]);
    
      useEffect(() => {
        if (!currentModal && modalQueue.length > 0) {
          handleNextModal();
        }
      }, [currentModal, modalQueue, handleNextModal]);


    const convertTypeToColor = (type: "info" | "error" | "warning" | "success") => {
        switch (type) {
            case "info":
                return "blue.500"
            case "error":
                return "red.500"
            case "warning":
                return "yellow.500"
            case "success":
                return "green.500"
            default:
                return "gray.500"
        }
    }

    const debounceClearToast = debounce(() => {
        toast.closeAll();
      }, 3000); // 
      
    useEffect(() => {
        if (info.length > 0) {
          info.forEach((item) => {
            // Afficher le toast
            if ( !toast.isActive(item.id)) {
                toast({
                    id: item.id,
                    position: "top-right",
                    duration: 1000,
                    isClosable: true,
                    containerStyle:{minWidth:"auto"},
                    onCloseComplete: () => {
                        // Supprimer l'élément de la liste une fois le toast fermé
                        setInfo((prevInfo) => prevInfo.filter((prevItem) => prevItem.id !== item.id));
                    },
                    render: () => (
                        <Box borderRadius="md" color='white' py={2} px={3} bg={convertTypeToColor(item.type)}>{item.message}</Box>
                    )
                });
            }
          });

          debounceClearToast();

            return () =>  debounceClearToast.cancel();
        }
    }, [info, toast]);

    const debounceClearError = debounce(() => {
        setError([]);
      }, 10000); // 

    useEffect(() => {
        if (error.length > 0) {
            error.forEach((item) => {
            // Afficher le toast
            if ( !toast.isActive(item.id)) {
                toast({
                    id: item.id,
                    position: "bottom",
                    duration: parseInt(process.env.NEXT_PUBLIC_NOTIFICATION_DELAY as string),
                    isClosable: true,
                    containerStyle:{minWidth:"auto"},
                    onCloseComplete: () => {
                        // Supprimer l'élément de la liste une fois le toast fermé
                        setError((prevInfo) => prevInfo.filter((prevItem) => prevItem.id !== item.id));
                    },
                    render: () => (
                        <Box borderRadius="md" color='white' py={2} px={3} bg={convertTypeToColor(item.type)}>{item.message}</Box>
                    )
                });
            }
          });

          debounceClearToast();

            return () => debounceClearToast.cancel();
        }
    }, [error, toast]);

    useEffect(() => {
        if (doVibrate) {
            if (window.ReactNativeWebView) {
                const data:WebviewType= {
                    domain: "GOOBLIES",
                    action: "vibrate",
                    payload: {
                        vibrate: doVibrate===true?[200, 50, 200]:doVibrate as number[],
                    }
                }
                window.ReactNativeWebView.postMessage(JSON.stringify(data));
            }else if (Boolean(window.navigator.vibrate)){
                window.navigator.vibrate(doVibrate===true?[200, 50, 200]:doVibrate as number[]);
                setDoVibrate(false);
            }
        }
    },[doVibrate])

    /*
    useEffect(() => {
        const interval = setInterval(() => {
            const now = Date.now();

               setMessages((prevMessages) =>
                    prevMessages.map((msg) =>{
                        if (msg.status == "queued" && msg.timestamp <= now) {
                            return { ...msg, status: "unread" }; 
                        }
                        if (msg.status == "unread" && msg.timestamp <= now - 5000) {
                            return { ...msg, status: "read" };
                        }
                        return msg;
                    }
                    
                    )
                );
        
        }, 1000);
    
        return () => clearInterval(interval);
      }, []);
*/
    return (
        <NotificationContext.Provider value={{
            info,
            error,
            messages,
            addInfo,
            addError,
            numberOfUnreadMessages,
            markMessageAsRead,
            markMessageAsTreated,
            addMessageWithId,
            voiceTalking,
            setVoiceTalking,
            setDoVibrate,
            isRecentMessage,
            emptyMessages,
            addModalToQueue,
            closeModal,
            defaultCharacter,
            setDefaultCharacter,
            character
        }}>           
            {currentModal && currentModal.doConfetti && <ConfettiExplosion 
                    zIndex={1500}
                    force={0.8}
                    duration={2000}
                    particleCount={250}/> }
            {isReady && defaultCharacter !=null &&  defaultCharacter !==undefined && props.children}         
            {currentModal && (
                <currentModal.component {...currentModal.props} isOpen onClose={closeModal} />
            )}   
            {isReady && defaultCharacter ==null && <ChooseCharacter/> }    
        </NotificationContext.Provider>
    )
}