'use client'

import { AllCharactersConst } from "@/app/const/allCharactersConst";
import TextToSpeechButton from "@/app/layout/global/textToSpeechButton";
import { UnorderedList, ListItem, Flex, HStack, VStack, Button, Text, Image, List, Badge } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useContext, useEffect, useState } from "react";
import { NotificationContext } from "../notificationContext";
import { storeLocalStorage } from "@/app/helpers/localStorageHelper";
import { CharacterType } from "../type/characterType";
import { chain } from "lodash";
import ImageNext from "next/image";

export default function ChooseCharacter() {
    const { t } = useTranslation();
    const [selectedCharacter, setSelectedCharacter] = useState<number>(0);
    const [localCharacter, setLocalCharacter] = useState<CharacterType|null>(null);

    const {
        setDefaultCharacter
    }= useContext(NotificationContext);
    
    useEffect(() => {
        if(selectedCharacter || selectedCharacter==0){
            setLocalCharacter(AllCharactersConst[selectedCharacter]);
        }
    },[selectedCharacter])

    const selectCharacter= (index:number)=>{
        storeLocalStorage("defaultCharacter", index);
        setDefaultCharacter(index);
    }

    useEffect(() => {
        AllCharactersConst.map((character, index) => {
            const img = document.createElement('img')
            img.src = character.background;
        })
    },[])

    return (
        <Flex 
            w="full" 
            h="full"  
            direction="column" 
            position="fixed" 
            top="0" 
            left="0" 
            zIndex="1000" 
            color="white" 
            bg="black"
        >
            <Flex
                position="absolute"
                top="0"
                left="0"
                w="100%"
                h="100%"
                key={`url(${localCharacter?.background})`}
                bgImage={`url(${localCharacter?.background})`}
                bgSize="cover"
                bgPosition="center"
                animation="backgroundZoomFade 0.3s ease-in-out forwards"
                zIndex="-1" // S'assurer qu'il est derrière
            />
            <Flex w="full" h="30vh" position="absolute" top="0" left="0" background="linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))"></Flex>
            <Flex w="full" h="30vh" position="absolute" bottom="0" left="0" background="linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))"></Flex>
            <Text zIndex={2} p={5} fontSize="lg">{t("chooseCharacter.title")}</Text>
            <Flex h="full" placeItems={"self-end"} p={5} pb={0}>
                <Text fontWeight="bold" fontSize="4xl" >{localCharacter?.name.toUpperCase()}</Text>                
            </Flex>
            <Flex w="full" px={5} pb={2} gap={2} flexWrap="wrap">
                {localCharacter?.details.map((detail, index) => {
                return (
                        <Badge key={index} px={2} py={2} borderRadius={"30px"} background="#354223" color="white">{t("chooseCharacter.badge."+detail)}</Badge>
                )})}
            </Flex>
            <HStack w="full" gap={5} px={5} zIndex={2}>
                {AllCharactersConst.map((character, index) => {
                    return (
                        <Flex onClick={()=>setSelectedCharacter(index)} justifyContent="center" key={index} direction="column" w="full" h="full" alignItems="center">  
                            <Image 
                                src={selectedCharacter==index&&false?character.imageTalking:character.image} 
                                alt={character.name} 
                                borderRadius={"50%"}
                                //opacity={selectedCharacter==index?"1":"0.75"}
                                filter={selectedCharacter==index?"":"grayscale(0.75)"}
                                border={selectedCharacter==index?"5px solid white":"5px solid #0000003d"} 
                                transform={selectedCharacter==index?"scale(1)":"scale(0.85)"}
                                transition="all 0.1s ease-in-out"
                                w="70px"
                                h="70px"
                            />    
                            <Text transition="all 0.1s ease-in-out" transform={selectedCharacter==index?"scale(1)":"scale(0.8)"} opacity={selectedCharacter==index?1:0.8} fontWeight={selectedCharacter==index?"bold":"normal"} fontSize="sm">{character.name.toUpperCase()}</Text>                        
                            {/*<UnorderedList h="125px">
                                {character.details.map((detail, index) => {
                                    return (
                                        <ListItem key={index} fontSize={"xs"}>{detail}</ListItem>
                                    )
                                })}
                            </UnorderedList>    */}                 
                        </Flex>
                    )
                })}                
            </HStack>     
            {/*<Image className="float" py={10} alignSelf="center" w="75px" src="/images/logo.png" alt="logo"/>        */}
            <VStack w="full" p={5} alignItems={"self-end"}>
                {selectedCharacter==null && <Button opacity="0.4" filter="grayscale(50%)" colorScheme="orange">{t("chooseCharacter.select")}</Button>}
                {selectedCharacter!==null && <Button colorScheme="orange" onClick={()=>selectCharacter(selectedCharacter)}>{t("chooseCharacter.select")}</Button>}
            </VStack>
                     
        </Flex>
    )
}